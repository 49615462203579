<template>
	<w-layout v-if="project !== null" column fill-height>
		<w-flex shrink>
			<v-toolbar color="primary" dark flat height="78px">
				<w-layout align-center justify-space-between row>
					<w-flex shrink>
						<w-btn v-if="value.can_rename && !editTitle" color="white" flat icon="edit" mini @click="editTitle = true">
							{{ $t('actions.update') }}
						</w-btn>
					</w-flex>
					<w-flex v-if="editTitle" grow>
						<w-text-input
							v-model="project.name"
							color="white"
							:label="$t('projects.project_name')"
							maxlength="191"
							required
							@blur="renameProject()"
							@keyup.esc="cancelTitleEditing()"
							@keyup.enter="renameProject()"
						/>
					</w-flex>
					<w-flex v-else shrink text-truncate title>
						{{ value.name }}
					</w-flex>
					<w-spacer />
					<w-flex shrink>
						<SelectWithChips
							v-if="value.can_change_status"
							v-model="projectStatus"
							color="white"
							dense
							:items="statuses"
							item-text="name"
							item-value="id"
							:label="$tc('projects.statuses', 1)"
							list-with-chips
							:loading="isStatusListLoading"
							prepend-inner-icon="play_arrow"
							return-object
							@change="changeStatus"
						/>
						<v-chip v-else :color="value.status.color" small>{{ value.status.name }}</v-chip>
					</w-flex>
					<w-flex shrink>
						<w-btn v-if="value.can_delete" color="error" flat icon="delete" mini @click="deleteProject()">
							{{ $t('actions.delete') }}
						</w-btn>
						<w-btn color="white" flat icon="close" mini @click="closeDetails()">{{ $t('close') }}</w-btn>
					</w-flex>
				</w-layout>
			</v-toolbar>
		</w-flex>
		<w-flex scroll-y>
			<w-section sticky :title="$t('projects.general_informations') + ' :'">
				<template v-if="value.can_change_dates || value.can_change_is_renewable" v-slot:header>
					<v-scale-transition>
						<w-btn v-show="!isUpdating" flat icon="edit" mini @click="launchProjectEdition()" />
					</v-scale-transition>
				</template>
				<w-layout column>
					<w-date-range-picker
						:clearable="false"
						:error-messages="rangePickerErrors"
						:first-date.sync="project.start"
						:second-date.sync="project.end"
						:readonly="!value.can_change_dates || !isUpdating"
						required
					/>
					<w-flex xs12>
						<v-select
							v-model="project.manager_id"
							color="primary"
							:readonly="!isUpdating"
							:items="accountingFirmUsers"
							item-text="username"
							item-value="id"
							:label="$t('projects.project_manager')"
							:loading="accountingFirmUsersLoading"
						/>
					</w-flex>
					<w-flex xs12>
						<v-select
							v-model="project.users"
							color="primary"
							:readonly="!isUpdating"
							:items="accountingFirmUsers"
							item-text="username"
							item-value="id"
							:label="$t('projects.project_collaborators')"
							:loading="accountingFirmUsersLoading"
							multiple
						/>
					</w-flex>
					<w-checkbox
						v-model="project.is_renewable"
						class="ma-0"
						:label="$t('projects.contract_renewal')"
						:readonly="!value.can_change_is_renewable || !isUpdating"
					/>
					<w-text-alert
						v-if="value.is_renewable && value.contains_non_renewable_services"
						class="mt-1"
						:text="$t('projects.project_contains_non-renewable_services')"
					/>
				</w-layout>

				<w-layout justify-end row :style="'min-height:48px'" wrap>
					<w-flex xs12>
						<w-list v-if="value.engagement_letter">
							<w-divider />
							<w-list-tile @click="showEngagementLetterPreview = true">
								<w-list-tile-avatar>
									<v-img :src="value.engagement_letter.version ? value.engagement_letter.version.thumbnail : undefined">
										<template v-slot:placeholder>
											<v-layout fill-height align-center justify-center ma-0>
												<v-progress-circular color="primary" indeterminate></v-progress-circular>
											</v-layout>
										</template>
									</v-img>
								</w-list-tile-avatar>
								<w-list-tile-content>
									<w-list-tile-title>{{ value.engagement_letter.name }}</w-list-tile-title>
									<w-list-tile-sub-title v-if="value.engagement_letter.version"
										>{{
											$t('documents.created_on', {
												date: $d(new Date(value.engagement_letter.created_at))
											})
										}}
									</w-list-tile-sub-title>
								</w-list-tile-content>
							</w-list-tile>
							<w-divider />
						</w-list>
					</w-flex>
					<w-flex v-if="!isUpdating && value.can_generate_engagement_letter" text-xs-right xs12>
						<EngagementLetterGenerationButton v-model="showEngagementLetterWizard" />
					</w-flex>
					<w-btn v-show="isUpdating" flat @click="cancelEditing()">{{ $t('actions.cancel') }}</w-btn>
					<w-btn v-show="isUpdating" :disabled="!project.is_valid" @click="updateProject">{{ $t('actions.save') }} </w-btn>
				</w-layout>
			</w-section>
			<w-section sticky :title="$tc('projects.services', value.services.length) + ' :'">
				<w-layout v-if="value.services.length > 1" align-center mb-2 row wrap>
					<w-flex grow ml-1>
						<w-layout align-start justify-start row>
							<w-flex v-for="category in value.categories" :key="category.id" shrink>
								<LabelledCounter :count="category.count" :label="category.name" :color="category.color" />
							</w-flex>
						</w-layout>
					</w-flex>
					<w-flex mr-1 shrink>
						<w-btn v-if="value.can_change_services" icon="add" small @click="addServices()">{{ $t('actions.add') }} </w-btn>
					</w-flex>
				</w-layout>
				<w-layout v-else justify-end row>
					<w-btn v-if="value.can_change_services" icon="add" small @click="addServices()">{{ $t('actions.add') }} </w-btn>
				</w-layout>
				<ServicesList v-if="value.services.length > 0" :value="value.services" />
			</w-section>
		</w-flex>
		<w-flex font-italic mb-1 shrink text-xs-center v-text="authorText" />
		<EngagementLetterWizard
			v-if="showEngagementLetterWizard"
			:project="value"
			@cancel="showEngagementLetterWizard = false"
			@input="setEngagementLetter($event)"
		/>
		<FilePreview v-if="showEngagementLetterPreview" :value="value.engagement_letter" @close="showEngagementLetterPreview = false" />
		<ProjectDeletion v-if="deleteProjectDialog" :value="value" @close="onProjectDeletion($event)" />
		<TasksDeletion v-if="deleteProjectTasksDialog" :status="projectStatus" :project-id="value.id" @close="onCloseDeleteTasks($event)" />
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
import { mapState } from 'vuex'
import DocumentVersionsService from '../../../services/Documents/DocumentVersionsService'

import Vue from 'vue'

export default {
	name: 'ProjectDetails',
	components: {
		EngagementLetterWizard: () => ({
			component: import('@/components/Offers/CustomersManager/EngagementLetterWizard')
		}),
		FilePreview: () => ({
			component: import('@/components/Commons/Files/FilePreview')
		}),
		LabelledCounter: () => ({
			component: import('@/components/Commons/LabelledCounter')
		}),
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		}),
		ServicesList: () => ({
			component: import('@/components/Offers/CustomersManager/ServicesList')
		}),
		ProjectDeletion: () => ({
			component: import('@/components/Offers/CustomersManager/Forms/ProjectDeletion')
		}),
		TasksDeletion: () => ({
			component: import('@/components/Offers/CustomersManager/Forms/TasksDeletion')
		}),
		EngagementLetterGenerationButton: () => ({
			component: import('@/components/Offers/CustomersManager/EngagementLetterGenerationButton')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			deleteProjectTasksDialog: false,
			deleteProjectDialog: false,
			editTitle: false,
			isUpdating: false,
			isUpdatingStatus: false,
			project: null,
			projectStatus: null,
			showEngagementLetterPreview: false,
			showEngagementLetterWizard: false,
			accountingFirmUsers: null,
			accountingFirmUsersLoading: true
		}
	},
	computed: {
		...mapState({
			isAccountantAdmin: state => state.accountingFirm.selected.isAccountantAdmin,
			statuses: state => state.customers.projects.statuses
		}),
		authorText: function () {
			if (!this.value.author) {
				return this.$t('projects.this_project_was_created_on', {
					date: this.$d(new Date(this.value.created_at))
				})
			} else if (this.value.author.email) {
				return this.$t('projects.this_project_was_created_on_the__by__', {
					date: this.$d(new Date(this.value.created_at)),
					username: this.value.author.username
				})
			}
			return this.$t('projects.this_project_was_renewed')
		},
		isStatusListLoading: function () {
			return this.$store.state.customers.projects.loaders['listStatuses'] || this.isUpdatingStatus
		},
		rangePickerErrors: function () {
			if (this.isUpdating && !this.project.are_dates_of_services_valid) {
				return [this.$t('projects.errors.dates_of_services_are_not_contained')]
			}
			return []
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (newVal, oldVal) {
				this.isUpdating = false
				if (newVal?.id !== oldVal?.id) {
					this.editTitle = false
				}
				if (newVal?.id) {
					this.project = this.value.clone()
					this.project.users = this.project.users.map(a => a.id)
					this.projectStatus = this.project.status
				} else {
					this.project = null
				}
				this.fetchUserAccountingFirmList(this.accountingFirmUsers !== null)
			},
			immediate: true
		},
		'value.engagement_letter': {
			handler: 'onEngagementLetterChanged',
			immediate: true
		}
	},
	methods: {
		changeStatus: function (event) {
			if (event?.key !== this.projectStatus?.key && this.projectStatus?.key) {
				this.updateStatusWithDeleteTasks(event)
			}
		},
		addServices: function () {
			this.appService.goTo({ name: 'customer-services-wizard', params: { projectId: this.value.id.toString(16) } })
		},
		cancelTitleEditing: function () {
			this.project.name = this.value.name
			this.editTitle = false
		},
		cancelEditing: function () {
			this.isUpdating = false
			this.project = this.value.clone()
		},
		closeDetails: function () {
			return this.appService.goTo('customer-services')
		},
		deleteProject: function () {
			this.deleteProjectDialog = true
		},
		launchProjectEdition: function () {
			this.isUpdating = true
		},
		onEngagementLetterChanged: function (document) {
			if (document) {
				return DocumentVersionsService.getDocumentVersions(this.vendorId, document).then(versions => {
					const engagementLetter = this.value.engagement_letter
					if (versions && engagementLetter && engagementLetter.hasOwnProperty('version')) {
						Object.assign(engagementLetter.version, versions[0])
					} else if (versions && engagementLetter) {
						Vue.set(engagementLetter, 'version', versions[0])
					}
				})
			}
		},
		renameProject: function () {
			this.service.renameProject(this.value.id, this.project.name).then(() => {
				this.editTitle = false
			})
		},
		setEngagementLetter: function (engagementLetter) {
			this.eventBus.emit(this.events.PROJECT_UPDATED, {
				id: this.value.id,
				engagement_letter: engagementLetter
			})
			this.onEngagementLetterChanged(engagementLetter)
			this.showEngagementLetterWizard = false
		},
		updateProject: function () {
			return this.service.updateProject(this.value.id, this.project)
		},
		updateStatusWithDeleteTasks: function (status) {
			if (status.key == 'not-signed') {
				this.deleteProjectTasksDialog = true
			} else {
				this.updateStatus(status)
			}
		},
		updateStatus: function (status) {
			this.isUpdatingStatus = true
			return this.service
				.updateProject(this.value.id, {
					status_id: status.id
				})
				.then(() => {
					this.appEventBus.emit(
						this.appEvents.SNACKBAR_SUCCESS,
						this.$t('projects.successes.status_updated', {
							name: this.value.name
						})
					)
				})
				.finally(() => {
					this.isUpdatingStatus = false
					this.isUpdating = false
				})
		},
		onCloseDeleteTasks: function (status) {
			this.deleteProjectTasksDialog = false
			this.updateStatus(status)
		},
		fetchUserAccountingFirmList: async function (silently) {
			if (this.project?.id === undefined) {
				return
			}
			if (!silently) {
				this.accountingFirmUsersLoading = true
			}
			this.accountingFirmUsers = await this.service.listAccountingFirmUsers(this.project.id)
			if (!silently) {
				this.accountingFirmUsersLoading = false
			}
		},
		onProjectDeletion: function (event) {
			this.deleteProjectDialog = false
			if (event) {
				this.closeDetails()
			}
		}
	}
}
</script>
